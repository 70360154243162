<template>
  <v-container class="container-card">
    <v-card>
      <SectionListCampanha />
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import SectionListCampanha from "@/components/campanha/apuracao/SectionListCampanha";

export default {
  name: "ApuracaoCampanha",

  components: {
    SectionListCampanha
  },

  methods: {
    ...mapActions({
      fetchView: "sistema/fetchView"
    })
  },

  mounted() {
    this.fetchView("Apuração Campanha");
  }
};
</script>
