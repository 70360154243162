<template>
  <v-dialog v-model="dialog" max-width="40%" @click:outside="$emit('close')">
    <v-card>
      <v-card-title>
        Aprovação Pagamento: {{ campanha }}
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-timeline
            v-show="historico.length > 0"
            align-top
            :dense="$vuetify.breakpoint.smAndDown"
          >
            <v-timeline-item
              v-for="(item, i) in historico"
              :key="i"
              :color="item.status == 'S' ? 'green' : 'red'"
              :icon="item.status == 'S' ? 'mdi-check' : 'mdi-clase'"
              fill-dot
            >
              <v-card :color="item.status == 'S' ? 'green' : 'red'" dark>
                <v-card-title class="text-h6">
                  {{ item.nome }}
                </v-card-title>
                <v-card-subtitle>
                  <span class="font-weight">{{
                    item.data_aprovado | BrazilianStandardDateAndTime
                  }}</span>
                </v-card-subtitle>
                <v-card-text class="white text--primary">
                  <div class="py-2">
                    {{ item.observacao }}
                  </div>
                </v-card-text>
              </v-card>
            </v-timeline-item>
          </v-timeline>
          <h2 v-show="historico.length == 0">Não há histórico</h2>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import dateMixin from "@/mixins/dateMixin";

export default {
  name: "DialogTimeLine",

  mixins: [dateMixin],

  props: {
    dialog: {
      type: Boolean
    },
    historico: {
      type: Array,
      default: () => []
    },
    campanha: {
      type: String
    }
  },
};
</script>
