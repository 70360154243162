<template>
  <v-menu :close-on-content-click="false" max-width="320" offset-x>
    <template v-slot:activator="{ on }">
      <FilterBottom v-on="on" />
    </template>

    <v-card>
      <v-container>
        <BaseFilter
          prepend-inner-icon="mdi-domain"
          clearable
          single-line
          label="Empresa"
          item-text="apelido"
          item-value="apelido"
          service="sistemaService.empresa"
          :filters="{
            calcula_comissao: 'S'
          }"
          v-model="filters.apelido"
          :isCompany="true"
          @change="handleFilter()"
        />
        <DatePickerMonth
          chips
          :currentDate="false"
          :dateValue="getStorage()"
          @date="assignDateFilter($event)"
        />
      </v-container>
    </v-card>
  </v-menu>
</template>

<script>
import FilterBottom from "@/components/shared/bottons/FilterBottom";
import BaseFilter from "@/components/shared/BaseSelectService";
import DatePickerMonth from "@/components/shared/DatePickerMonth";

export default {
  name: "Filtro",

  components: {
    FilterBottom,
    BaseFilter,
    DatePickerMonth
  },

  data() {
    return {
      filters: {}
    };
  },

  methods: {
    handleFilter() {
      this.$emit("selectedFilters", this.filters);
      this.setStorage();
    },

    assignDateFilter(date) {
      this.filters["ano_ref"] = date?.ano_ref;
      this.filters["mes_ref"] = date?.mes_ref;
      this.handleFilter();
    },

    handleStoragedFilter() {
      if (!this.getStorage()) this.setStorage();
      this.filters = this.getStorage();
    },

    getStorage() {
      return JSON.parse(localStorage.getItem("filters"));
    },

    setStorage() {
      localStorage.setItem("filters", JSON.stringify(this.filters));
    }
  },

  mounted() {
    this.handleStoragedFilter();
    this.handleFilter();
  }
};
</script>
